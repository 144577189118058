<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch filterTimeStr="thisMonth" v-model="queryParams" :getList="getList" :dropDownList="dropDownList"
            :isSearchInput="true" isSearchInputTitle="单据编号、仓库编号、名称" @isShowHighCom="getIsShowHigh" />
          <div v-show="showHigh">
            <div class="marT10 x-f">
              <span class="fS14MR10">仓库</span>
              <SelectRemote :multiple="true" class="marR15" v-model="queryParams.inStoreIds" style="width: 250px"
                :option="
                  $select({
                    key: 'listFreshly',
                    // option: {
                    //   option: {
                    //     buttons: [
                    //       {
                    //         type: 'more',
                    //         option: {
                    //           title: '选择仓库',
                    //           width: 1250,
                    //           type: 'TreeAndTable',
                    //           formData: $dialog({ key: 'store' }),
                    //         },
                    //       },
                    //     ],
                    //   },
                    // },
                  }).option
                " />
              <span class="fS14MR10">单据状态</span>
              <SelectLocal :multiple="true" v-model="queryParams.billStatuss" style="width: 250px" :option="{
                  data: dict.type.bill_status,
                  labels: [{ title: '名称', label: 'label' }],
                  value: 'value',
                  label: 'label',
                }" />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="单据信息" class="orderInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isAuditBtn="true" :multiple="multiple" @handleAdd="handleAdd"
            @handleDelete="auditBill('删除')" @handleAudit="auditBill('审核')" @handleRedoAudit="auditBill('反审核')" />
          <el-table :data="tableData" tooltip-effect="dark" @selection-change="handleSelectionChange"
            @row-click="handleRowClick" border v-loading="loadingTable" ref="multipleTable" :max-height="tableHeight"
            :height="tableHeight" style="width: 100%">
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
            <el-table-column show-overflow-tooltip label="单据编号" align="center" prop="billNo" min-width="160">
              <template slot-scope="scope">
                <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                  {{ scope.row.billNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="单据状态" align="center" prop="billStatusName" min-width="120" />
            <el-table-column label="仓库编号" align="center" prop="inStoreNo" min-width="160" />
            <el-table-column label="仓库名称" align="center" prop="inStoreName" min-width="160" />
            <el-table-column label="单据金额" align="center" prop="billMoney" min-width="120" />
            <el-table-column label="审核人" align="center" prop="auditBy" min-width="120" />
            <el-table-column label="审核时间" align="center" prop="auditTime" min-width="155" />
            <el-table-column label="备注" align="center" prop="billRemark" min-width="155" />
            <el-table-column label="修改人" align="center" prop="updateBy" min-width="120" />
            <el-table-column label="修改时间" align="center" prop="updateTime" min-width="155" />
            <el-table-column label="创建人" align="center" prop="createBy" min-width="120" />
            <el-table-column label="创建时间" align="center" prop="createTime" min-width="155" />
          </el-table>
          <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
            @selectAllCom="selectAll" />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>

import { getProduceList, updateBillStatus } from "@/api/freshlyBaked/bill/produce"; //生产
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import bottomPage from "@/views/components/bottomPage"; //底部分页
import operatingButton from "@/views/components/operatingButton"; //操作按钮
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
export default {
  name: "moveOut",
  dicts: ["bill_status"],
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectRemote,
    operatingButton,
    SelectLocal,
  },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.queryParams.billStatus = to.query.billStatus;
      }
    });
  },
  data() {
    return {
      loadingTable: false,
      //搜索框搜索的条件
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "billNos", name: "单据编号" },
        { parameter: "inStoreNos", name: "仓库编号" },
        { parameter: "inStoreNames", name: "仓库名称" },
      ],
      //是否显示高级搜索
      showHigh: false,
      ids: [], // 选中数组的Id
      billStatusZeroId: [], //选中数组的未审核的状态0
      billStatusTwoId: [], //选中数组的已审核的状态2
      billStatusThreeId: [], //选中数组的已删除的状态3
      total: 0, // 总条数
      loading: false, //加载框
      multiple: true, //删除、审核的禁用
      tableData: [], //表格
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 130;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value;
      this.$nextTick(() => {
        this.handleResize();
      });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.billId); //单据id
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map((item) => {
          if (item.billStatus === "0") {
            return item.billId;
          }
        })
        .filter((ite) => typeof ite !== "undefined");
      //筛选出来已审核的单据状态id2
      this.billStatusTwoId = selection
        .map((item) => {
          if (item.billStatus === "2") {
            return item.billId;
          }
        })
        .filter((ite) => typeof ite !== "undefined");
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map((item) => {
          if ((item.billStatus != "3") & (item.billStatus === "0")) {
            return item.billId;
          }
        })
        .filter((ite) => typeof ite !== "undefined");
      this.multiple = !selection.length;
    },

    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true;
        const res = await getProduceList({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total,
        });
        //重新渲染表格
        this.tableData = res.rows;
        //表格多选
        this.tableData.map((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value);
          });
        });
        this.loadingTable = false;
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
        this.getList();
      }
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.billStatusZeroId.length === 0) {
            this.$message.error("勾选单据中未审核的单据哦~");
            return;
          }
          const obj = {
            billIds: this.billStatusZeroId,
            billStatus: 2,
            billType: 170402,
          };
          //发送审核api
          await updateBillStatus(obj);
          // 弹出提示
          this.$message.success("审核成功");
        } else if (name === "反审核") {
          if (this.billStatusTwoId.length === 0) {
            this.$message.error("勾选单据中已审核的单据哦~");
            return;
          }
          const obj = {
            billIds: this.billStatusTwoId,
            billStatus: 0,
            billType: 170402,
          };
          //发送反审核api
          await updateBillStatus(obj);
          // 弹出提示
          this.$message.success("反审核成功");
        } else if (name === "删除") {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error("勾选单据中未审核的单据哦~");
            return;
          }
          const obj = {
            billIds: this.billStatusThreeId,
            billStatus: 3,
            billType: 170402,
          };
          //发送删除api
          await updateBillStatus(obj);
          // 弹出提示
          this.$message.success("删除成功");
        }
        await this.getList();
      } catch { }
    },
    /** 查询分页列表 */
    async getList() {
      this.loadingTable = true;
      const res = await getProduceList(this.queryParams);
      this.tableData = res.rows;
      this.total = res.total;
      this.loadingTable = false;
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "/api/system/vip/base/vipcard/export",
        {
          ...this.queryParams,
        },
        `post_${new Date().getTime()}.xlsx`
      );
    },
    //新增单据事件
    handleAdd() {
      this.$router.push({
        name: "FreshlyBakedProduceDetail",
        query: {
          type: "Add",
        },
      });
    },
    //跳转详情
    handleDetail(row) {
      const billId = row.billId;
      this.$router.push({
        name: "FreshlyBakedProduceDetail",
        query: {
          billId: billId,
          type: "Update",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//整体树
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);

  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .orderInfo {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
</style>
