<template>
  <div>
    <cardTitleCom cardTitle="现烤APP开通">
      <template slot="cardContent">
        <div class="head">
          <div class="head-logo" :style="`background-image:url(${require('@/assets/images/fpLogo.png')});`"></div>
          <div>
            <div style="font-size: 14px; line-height: 28px">
              <div style="font-size: 16px; font-weight: bold">现烤间APP</div>
              <div>
                <!-- 现烤间 -->
                用户可以根据实时消费情况提前制定生产计划，通过现烤APP实现即时生产，即时售卖打造新鲜烘焙，现烤现做，不卖隔夜面包/蛋糕，保证真材实料。满足市场需求，避免浪费，为商户创造更好的口碑，提高品牌的影响力。  
              </div>
              <!-- <div>价格：{{ price || '--' }}</div> -->
            </div>
            <div class="buttons">
              <div class="button" @click="goBuy">
                {{ price }} {{ isBuy == 'buy' ? '立即开通' : '立即续费' }}
              </div>
              <el-link type="primary" class="text-button" @click="goList">查询订单列表></el-link>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom :hideCard="true">
      <template slot="cardContent">
        <div class="x-fc" style="padding: 100px 0;">
          <div class="flex1" style="margin-left: 100px">
            <div v-for="(item, index) in introductionList" :key="index" class="y-start marB20">
              <div class="fontBold" style="margin-bottom: 10px;">{{ item.title }}</div>
              <div style="margin-left: 8px">{{ item.content }}</div>
            </div>
          </div>
          <el-image class="marR50" style="width: 35%; height: 100%"
            :src="require('@/assets/images/bh-panel.png')"></el-image>
        </div>
      </template>
    </cardTitleCom>
    <!-- <div class="head cardContent2 el-card is-always-shadow">
      <div class="text-view">
        <div style="font-size: 16px; font-weight: bold">产品介绍</div>
        <div class="text-p">
          随着电商的发展和门店成本的上升，烘焙连锁企业开始采用中央裱花间模式，提高效率和
          降低人力成本。裱花间
          APP是一款专为中央裱花间/工厂设计的智能管理系统，能够实时监控
          不同类型的业务订单的接单、生产、配送和签收过程。
        </div>
        <div class="text-p">
          通过裱花间
          APP，烘焙连锁企业可以实现裱花制作集中化、信息化，提升客户满意度。
          客户下单后，订单信息自动传送到裱花间平板并打印小票，裱花师根据订单信息及图片样式
          制作蛋糕，订单进度以及最新状态可实时同步到门店及C端用户，增强数据共享和协同优化。
        </div>
      </div>
      <div class="logo2" :style="`background-image:url(${require('@/assets/images/bh-panel.png')});`"></div>
    </div> -->
  </div>
</template>
<script>
import { getMinPay } from '@/utils'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  getOrderDetail,
  getTradeProductToRenew
} from '@/api/produce/decoration/index.js'
export default {
  name: 'goBuyIndex',
  components: { cardTitleCom },
  data() {
    return {
      introductionList: [
        {
          title: "【主要功能】",
          content: "隼云现烤APP特色：",
        },
        {
          title: "【主要功能】",
          content: "1、极大的方便了处理“前厅后厂”，有效提高企业的效率和管理水平。",
        },
        {
          title: "【生产管理】",
          content:
            "2、用户可根据销售预测和库存情况制定生产计划，确保提供优质的原材料订货和半成品订货。",
        },
        {
          title: "【报表管理】",
          content:
            "3、用户可以简单、快速实现所需报表的查询。用户可以自由增加、修改和删除报表，通过自定义的方式进行程序功能扩展和数据报表输出，方便统计营业额，原料消耗，现烤报损，生产绩效等。",
        },
        {
          title: "【库存管理】",
          content:
            "4、更专业的应对各类复杂的库存管理环节。它包括调出、调入、盘点、盘盈盘亏等模块，让您轻松应对复杂环境，降低企业库存管理成本和库存风险。",
        }
      ],
      isBuy: 'buy',
      price: '---'
    }
  },
  mounted() {
    // 获取价格
    this.getOrder()
  },
  methods: {
    async getOrder() {
      try {
        const { data } = await this.getDicts('product_years_package_mode')
        const yearsData = data || []
        //判断是新增还是续费
        const res1 = await getTradeProductToRenew()
        this.isBuy = res1.type
        const res = await getOrderDetail()
        console.log('res', res)
        const res2 = getMinPay(res.data, res1.type, yearsData)
        console.log('res2', res2)
        this.price = `￥${res2.pay}/${res2.text
          } 起`
      } catch (error) {
        console.log('priceArr error ', error)
      }
    },

    goBuy() {
      if (this.isBuy == 'buy') {
        this.$emit('update:active', 2)
        this.$emit('resume', {})
      } else {
        this.$emit('update:active', 4)
        this.$emit('resume', {})
      }
    },
    goList() {
      this.$emit('update:active', 3)
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  ::v-deep .cardContent {
    padding: 10px;
  }
  .cardContent2 {
    padding: 110px 70px;
    height: calc(100vh - 350px);
    min-height: 400px;
    .text-view {
      margin-right: 60px;
      flex: 1;
      .text-p {
        text-indent: 4ch;
        padding-top: 15px;
        font-size: 14px;
        line-height: 30px;
      }
    }
    .logo2 {
      margin-left: 60px;
      flex: 1;
      width: 542px;
      height: 400px;
      background-size: 100% 100%;
    }
  }
  .head {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .head-logo {
      height: 100px;
      border-radius: 5px;
      overflow: hidden;
      width: 100px;
      margin-right: 10px;
      margin-left: 10px;
      background-size: 100% 100%;
    }
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;

    .button {
      width: 220px;
      height: 39px;
      line-height: 39px;
      text-align: center;
      background: #f2f2f2;
      border-radius: 24px;
      font-size: 18px;
      color: #ffffff;
      background-color: #ff9e40;
      overflow: hidden;
      cursor: pointer;
    }

    .text-button {
      margin-left: 20px;
      font-size: 14px;
      height: 18px;
      margin-bottom: 10px;
    }
  }
}

</style>
